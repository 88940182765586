import { createStore } from 'vuex'
export default createStore({
  state: {
    audio_tx_jia: null,
    audio_tx: null,
    language: 'zh',
    languages: [{ 'label': '中文', 'value': 'zh' }, { 'label': 'English', 'value': 'en' }, { 'label': 'Tiếng Việt', 'value': 'vi' }],
  },
  getters: {
  },
  mutations: {
    setLanguage(state, language) {
      state.language = language;
      localStorage.setItem('language', language)
    },
  },
  actions: {
  },
  modules: {
  }
})
