export default [
  {
    path: '/system/service',
    name: 'systemService',
    component: () =>
      import(
        /* webpackChunkName: "systemService" */ '@/views/system/service/index.vue'
      ),
    meta: {
      title: '客服列表',
    },
  },
  {
    path: '/system/service2',
    name: 'systemService2',
    component: () =>
      import(
        /* webpackChunkName: "systemService2" */ '@/views/system/service2/index.vue'
      ),
    meta: {
      title: '客服设置',
    },
  },
  {
    path: '/system/interest',
    name: 'systemInterest',
    component: () =>
      import(
        /* webpackChunkName: "systemInterest" */ '@/views/system/interest/index.vue'
      ),
    meta: {
      title: '利息宝设置',
    },
  },
  {
    path: '/system/bank',
    name: 'systemBank',
    component: () =>
      import(
        /* webpackChunkName: "systemBank" */ '@/views/system/bank/index.vue'
      ),
    meta: {
      title: '银行设置',
    },
  },
  {
    path: '/system/sms',
    name: 'systemSms',
    component: () =>
      import(
        /* webpackChunkName: "systemSms" */ '@/views/system/sms/index.vue'
      ),
    meta: {
      title: '短信设置',
    },
  },
  {
    path: '/system/rebate',
    name: 'systemRebate',
    component: () =>
      import(
        /* webpackChunkName: "systemRebate" */ '@/views/system/rebate/index.vue'
      ),
    meta: {
      title: '佣金设置',
    },
  },
  {
    path: '/system/rate',
    name: 'systemRate',
    component: () =>
      import(
        /* webpackChunkName: "systemRate" */ '@/views/system/rate/index.vue'
      ),
    meta: {
      title: '默认货币设置',
    },
  },
  {
    path: '/system/popup',
    name: 'systemPopup',
    component: () =>
      import(
        /* webpackChunkName: "systemRate" */ '@/views/system/popup/index.vue'
      ),
    meta: {
      title: '弹窗管理',
    },
  },
]
