export default [
  {
    path: '/commodity/list',
    name: 'commodityList',
    component: () =>
      import(
        /* webpackChunkName: "interestRecord" */ '@/views/commodity/list/index.vue'
      ),
    meta: {
      title: '商品列表',
    },
  },
  {
    path: '/commodity/classify',
    name: 'commodityClassify',
    component: () =>
      import(
        /* webpackChunkName: "commodityClassify" */ '@/views/commodity/classify/index.vue'
      ),
    meta: {
      title: '商品分类',
    },
  },
]
