export default [
  {
    path: '/loan/options',
    name: 'loanOptions',
    component: () =>
      import(
            /* webpackChunkName: "interestOptions" */ '@/views/loan/options/index.vue'
      ),
    meta: {
      title: '贷款选项',
    },
  },
  {
    path: '/loan/record',
    name: 'loanRecord',
    component: () =>
      import(
            /* webpackChunkName: "interestRecord" */ '@/views/loan/record/index.vue'
      ),
    meta: {
      title: '贷款订单',
    },
  },
  {
    path: '/loan/return',
    name: 'loanReturn',
    component: () =>
      import(
            /* webpackChunkName: "interestRecord" */ '@/views/loan/return/index.vue'
      ),
    meta: {
      title: '还款订单',
    },
  },
]