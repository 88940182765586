export default [
    {
        path: '/lottery/options',
        name: 'lotteryOptions',
        component: () =>
          import(
            /* webpackChunkName: "interestOptions" */ '@/views/lottery/options/index.vue'
          ),
        meta: {
          title: '抽奖选项',
        },
      },
      {
        path: '/lottery/record',
        name: 'lotteryRecord',
        component: () =>
          import(
            /* webpackChunkName: "interestRecord" */ '@/views/lottery/record/index.vue'
          ),
        meta: {
          title: '抽奖记录',
        },
      },
]