<template>

  <a-config-provider :locale="zhCN">
    <router-view />


    <div>
      <audio controls ref="audio_tx" hidden="true" v-if="state.show">
        <source src="/video/tx.mp3" type="audio/ogg">
        <source src="/video/tx.mp3" type="audio/mpeg">
        您的浏览器不支持 audio 标签。
      </audio>
      <audio controls ref="audio_tx_jia" hidden="true" v-if="state.show">
        <source src="/video/tx_jia.mp3" type="audio/ogg">
        <source src="/video/tx_jia.mp3" type="audio/mpeg">
        您的浏览器不支持 audio 标签。
      </audio>
    </div>
  </a-config-provider>
</template>

<script setup>
import { reactive, onMounted, ref } from "vue";
import { useStore } from "vuex"
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
dayjs.locale('zh-cn');
const stroe = useStore();
const state = reactive({
  show: false,
  show1: false
})
const audio_tx = ref();
const audio_tx_jia = ref();
onMounted(() => {

  var element = document.querySelector('body'); // 获取要监听移动事件的元素

  element.addEventListener('click', function () {
    state.show = true;
    state.show1 = true;
    setTimeout(() => {
      stroe.state.audio_tx = audio_tx.value;
      stroe.state.audio_tx_jia = audio_tx_jia.value;
    },800)

  });



})
</script>

<style>
#app {
  width: 100%;
  height: 100%;

  font-size: 14px;
}
</style>
