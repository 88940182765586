export default [
    {
      path: '/trade/control',
      name: 'tradeControl',
      component: () =>
        import(
          /* webpackChunkName: "tradeControl" */ '@/views/trade/control/index.vue'
        ),
      meta: {
        title: '交易控制',
      },
    },
    {
      path: '/trade/order',
      name: 'tradeOrder',
      component: () =>
        import(
          /* webpackChunkName: "tradeOrder" */ '@/views/trade/order/index.vue'
        ),
      meta: {
        title: '订单列表',
      },
    },
    {
      path: '/trade/order/dummy',
      name: 'memberOrderDummy',
      component: () =>
        import(
          /* webpackChunkName: "memberOrderDummy" */ '@/views/trade/order/index.vue'
        ),
      meta: {
        title: '订单列表',
      },
    },
    {
      path: '/trade/withdraw/dummy',
      name: 'tradeWithdrawDummy',
      component: () =>
        import(
          /* webpackChunkName: "tradeWithdrawDummy" */ '@/views/trade/withdraw/index.vue'
        ),
      meta: {
        title: '提现列表',
      },
    },
    {
      path: '/trade/withdraw',
      name: 'tradeWithdraw',
      component: () =>
        import(
          /* webpackChunkName: "tradeWithdraw" */ '@/views/trade/withdraw/index.vue'
        ),
      meta: {
        title: '提现列表',
      },
    },
    {
      path: '/trade/recharge',
      name: 'tradeRecharge',
      component: () =>
        import(
          /* webpackChunkName: "tradeRecharge" */ '@/views/trade/recharge/index.vue'
        ),
      meta: {
        title: '充值列表',
      },
    },
    {
      path: '/trade/recharge/dummy',
      name: 'tradeRechargeDummy',
      component: () =>
        import(
          /* webpackChunkName: "tradeRechargeDummy" */ '@/views/trade/recharge/index.vue'
        ),
      meta: {
        title: '充值列表',
      },
    }
  ]
  