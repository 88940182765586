import { createI18n } from 'vue-i18n'
import zh from "./zh.json"
import en from "./en.json"
import vi from "./vi.json"
export const i18n = createI18n({
    allowComposition: true,
    locale: localStorage.getItem('language') || 'zh',
    messages: {
        zh,
        en,
        vi,
    }
})

